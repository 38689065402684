import "jquery-smooth-scroll";
import "jquery.easing";

/*
 *
 * ----------------------------------------------- */
// jQuery Smooth Scroll - v2.2.0 - 2017-05-05
// https://github.com/kswedberg/jquery-smooth-scroll
jQuery(function ($) {
  var $menuTrigger = $(".menu-trigger");

  $("[data-sm]").smoothScroll({
    offset: 0,
    beforeScroll: function () {
      $menuTrigger[0].click();
    },
  });
});
