// MEMO: import this for IE11.
// MEMO: Swiper 5.4.5 works on IE11.
// import Swiper from "../../node_modules/swiper/js/swiper";

// import the latest version
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay]);

/*
 *
 * ----------------------------------------------- */
var swiperSimple = document.querySelector(".swiper-simple");

if (swiperSimple) {
  new Swiper(swiperSimple, {
    slidesPerView: "auto",
    spaceBetween: 30,
    navigation: {
      nextEl: ".swiper-simple .swiper-button-next",
      prevEl: ".swiper-simple .swiper-button-prev",
    },
    breakpoints: {
      992: {
        spaceBetween: 45,
      },
    },
  });
}

/*
 *
 * ----------------------------------------------- */
var swiperVoice = document.querySelector(".swiper-voice");

if (swiperVoice) {
  new Swiper(swiperVoice, {
    slidesPerView: "auto",
    spaceBetween: 40,
    navigation: {
      nextEl: ".swiper-voice .swiper-button-next",
      prevEl: ".swiper-voice .swiper-button-prev",
    },
    breakpoints: {
      992: {
        spaceBetween: 50,
      },
    },
  });
}
