import "./polyfill/number-is-nan";
import "./modules/swiper";
import "./modules/aos";

// You can specify which plugins you need
// import { Tooltip, Toast, Popover } from "bootstrap";
import "bootstrap/js/dist/collapse";
// import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";
// import "bootstrap/js/dist/tab";
// import "bootstrap/js/dist/scrollspy";

import "./modules/webfont";
// import "./modules/view-more";
// import "./modules/page-top";
// import "./modules/toggle-disabled-input-field";
import "./modules/menu-active";
import "./modules/jqueryvalidation";
import "./modules/menu-trigger";
import "./modules/date-picker";
import "./modules/loading-screen";
// import "./modules/bs.tab";
// import "./modules/js-header";
// import "./modules/js-footer";
// import "./modules/waypoints";
// import "./modules/dropdown-hover";
import "./modules/smoothScroll";

import "./modules/jquery-match-height";
// import "./modules/luxy";
import "./modules/hide-btn-mobile";

// import ScrollMagic from "scrollmagic";
