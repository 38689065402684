import { isMobile } from "./isMobile";

/*
 * Fixed position footer
 * ----------------------------------------------- */
jQuery(window).on("load scroll", function () {
  if (isMobile()) {
    var $footer = $("footer");
    var $jsBtn = $(".btn-container-fixed");
    var topPosition = $footer.offset().top;
    var windowHeight = $(window).height() / 2;
    var scrollTop = $(window).scrollTop();

    if ($footer.length) {
      if (scrollTop >= topPosition - windowHeight - 400) {
        $jsBtn.addClass("is-scrolled");
      } else {
        $jsBtn.removeClass("is-scrolled");
      }
    }
  }
});
