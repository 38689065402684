if ($(".loading-screen").length) {
  $(".loading-screen").on("scroll", function () {
    var scroll = $(this).scrollTop();
    var $scaleImg = $(".img-scale");

    var scale = scroll > 0 ? 1 + 0.01 * scroll : 1;
    var opacity = scroll > 0 ? 1 - 0.2 * scroll : 1;
    var imgWidth = $scaleImg.width() * scale;
    var windowWidth = $(window).width() * 0.99;

    if (imgWidth > windowWidth) {
      $(this).addClass("hidden-loading");
      $("body").removeClass("overflow-hidden");
      setTimeout(function () {
        $(".loading-screen").addClass("loading-disabled");
        $(".section-fv").addClass("active");
      }, 400);
    }

    $scaleImg.css("transform", "scale(" + scale + ")");

    $(".logo-loading").css("opacity", "" + opacity + "");
  });
} else {
  $("body").removeClass("overflow-hidden");

  setTimeout(function () {
    $(".section-fv").addClass("active");
  }, 400);
}
