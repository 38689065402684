/*
 * menu-trigger btn
 * ----------------------------------------------- */
jQuery(function ($) {
  var $menuTrigger = $(".menu-trigger");

  $.each($menuTrigger, function (i) {
    var $this = $(this);
    var id = $this.attr("data-bs-target");

    $(id).on("show.bs.collapse", function () {
      $(".navbar").addClass("active");

      setTimeout(function () {
        $(".btn-container-fixed").addClass("hide-btn-fixed");
      }, 100);

      setTimeout(function () {
        $("body").addClass("disable-scroll");
      }, 300);
    });

    $(id).on("hide.bs.collapse", function () {
      $(".navbar").removeClass("active");
      $("body").removeClass("disable-scroll");

      setTimeout(function () {
        $(".btn-container-fixed").removeClass("hide-btn-fixed");
      }, 400);
    });
  });
});
