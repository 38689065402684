/*
 *
 * ----------------------------------------------- */
export function splitText() {
  $(".split-text").text(function () {
    var str = $(this).text().trim();
    var html = "";

    for (var i = 0; i < str.length; i++) {
      html += "<span>" + str.charAt(i) + "</span>";
    }

    $(this).empty().html(html);
  });
}
