import "jquery-ui-bundle";

/* Japanese initialisation for the jQuery UI date picker plugin. */
jQuery(function ($) {
  $.datepicker.regional.ja = {
    closeText: "閉じる",
    prevText: "&#x3c;前",
    nextText: "次&#x3e;",
    currentText: "今日",
    monthNames: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
    ],
    monthNamesShort: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
    ],
    dayNames: [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日",
    ],
    dayNamesShort: ["日", "月", "火", "水", "木", "金", "土"],
    dayNamesMin: ["日", "月", "火", "水", "木", "金", "土"],
    weekHeader: "週",
    dateFormat: "yy年mm月dd日",
    firstDay: 0,
    isRTL: false,
    showMonthAfterYear: true,
    yearSuffix: "年",
  };
  $.datepicker.setDefaults($.datepicker.regional.ja);
});
$(function () {
  $(".date-picker").datepicker($.datepicker.regional.ja);
});
