jQuery(function (jQuery) {
  var jQuerysectionIds = jQuery(".nav-item-child a.nav-link");

  jQuery(document).scroll(function () {
    jQuerysectionIds.each(function () {
      var container = jQuery(this).attr("href");
      if ($(container).length) {
        var containerOffset = jQuery(container).offset().top;
        var containerHeight = jQuery(container).outerHeight();
        var containerBottom = containerOffset + containerHeight;
        var scrollPosition = jQuery(document).scrollTop();

        if (
          scrollPosition < containerBottom - 20 &&
          scrollPosition >= containerOffset - 20
        ) {
          jQuery("a.nav-link").removeClass("active");
          jQuery(this).addClass("active");
        } else {
          jQuery(this).removeClass("active");
        }
      }
    });
  });
});
